import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { UsersService } from '../userManagement/users.service';

@Injectable({
  providedIn: 'root'
})
export class StorageServiceService {

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private UserService: UsersService,
  ) { }
  getData(key:any): any {
    const storedData = localStorage.getItem(key);
    if (storedData) {
      console.log('storeDataWorking')
      return JSON.parse(storedData);
    } else {
      // Fetch data from an API (simulated here with a Promise)
      this.fetchDataFromAPI().then(data => {
        // localStorage.setItem(key, JSON.stringify(data));
       let newstoredData = localStorage.getItem(key);
       console.log('newstoredData',newstoredData)
        return JSON.parse(newstoredData);
      });
    }
  }

//   private fetchDataFromAPI(): Promise<any> {
//     // Simulated API call - replace with actual API call
//     const token = localStorage.getItem('token');
//     if(token){
// console.log('tokentoken',token)
//       return new Promise(resolve => {
//         setTimeout(() => {
//           const mockData = { message: 'Data from API' };
//           resolve(mockData);
//         }, 1000); // Simulating delay
//       });
//     }else{
//       this._router.navigateByUrl('sign-in');
//       this._router.navigate(['sign-in']);
//       localStorage.clear();
//       this._authService.signOut();
//     }
//   }
fetchDataFromAPI(): Promise<any> {
  // Retrieve the token from local storage
  const token = localStorage.getItem('token');
  
  if (token) {
    console.log('Token:', token);
    
    // Use the userIndex method to fetch data from the API
    return this.UserService.userIndex().toPromise().then((response:any) => {
      // Process the response data as needed
      console.log('response',response?.data.userpermissions)
       // Adjust based on the actual API response structure
      
      // Optionally, handle response transformation here if needed
      // localStorage.setItem('admin',JSON.stringify(data?.data.admin))
      localStorage.setItem('userPermission',JSON.stringify(response?.data.userpermissions))
      return response;
    }).catch(error => {
      // Handle any errors that occur during the API call
      console.error('API call failed', error);
      throw error;
    });
    
  } else {
    // Handle case where token is missing
    this._router.navigateByUrl('sign-in');
    localStorage.clear();
    this._authService.signOut();
    return Promise.reject('Token is missing');
  }
}
}
