import { AsyncPipe, CurrencyPipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { Router, RouterLink } from '@angular/router';
import { initFlowbite } from 'flowbite';
import * as XLSX from 'xlsx';
import { JobService } from 'app/core/services/jobListing/job.service';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'app/core/services/userManagement/users.service';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports        : [ MatDialogModule,RouterLink,NgIf, MatProgressBarModule, MatFormFieldModule, MatIconModule, MatInputModule, FormsModule, ReactiveFormsModule, MatButtonModule, MatSortModule, NgFor, NgTemplateOutlet, MatPaginatorModule, NgClass, MatSlideToggleModule, MatSelectModule, MatOptionModule, MatCheckboxModule, MatRippleModule, AsyncPipe, CurrencyPipe],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private toastr: ToastrService,
    private usersService: UsersService,
    private _router: Router,
    private _userService: UserService,
    private _authService: AuthService,
  ) {}

  old_password:any
  new_password:any
  confirm_password:any
  hideold_password: boolean = true;
  hidenew_password: boolean = true;
  hideconfirm_password: boolean = true;

  toggleOldPasswordVisibility(): void {
    this.hideold_password = !this.hideold_password;
    const passwordInput = document.getElementById('old_password');
    if (passwordInput) {
      passwordInput.setAttribute('type', this.hideold_password ? 'password' : 'text');
    }
  }
  toggleNewPasswordVisibility(): void {
    this.hideold_password = !this.hideold_password;
    const passwordInput = document.getElementById('new_password');
    if (passwordInput) {
      passwordInput.setAttribute('type', this.hideold_password ? 'password' : 'text');
    }
  }
  toggleConfirmPasswordVisibility(): void {
    this.hideold_password = !this.hideold_password;
    const passwordInput = document.getElementById('confirm_password');
    if (passwordInput) {
      passwordInput.setAttribute('type', this.hideold_password ? 'password' : 'text');
    }
  }
  signOut(): void
  {
      console.log('working')
      this._router.navigateByUrl('sign-in');
      this._router.navigate(['sign-in']);
      localStorage.clear();
      this._authService.signOut();
  }
  validatePassword(password: string): Boolean {
    let minLength = 8;
let maxLength = 14;

// Regex patterns for each requirement
let hasDigit = /\d/;
let hasLowercase = /[a-z]/;
let hasUppercase = /[A-Z]/;
let hasSpecialChar = /[!@#$%^&*()\-=_+]/;
let noSpaces = /^\S*$/; // Ensures no spaces
    let errorMessage = '';

    if (password.length < minLength || password.length > maxLength) {
      errorMessage += `New Password must be between ${minLength} and ${maxLength} characters long. `;
    }
    if (!hasDigit.test(password)) {
      errorMessage += 'New Password must contain at least one digit. ';
    }
    if (!hasLowercase.test(password)) {
      errorMessage += 'New Password must contain at least one lowercase letter. ';
    }
    if (!hasUppercase.test(password)) {
      errorMessage += 'New Password must contain at least one uppercase letter. ';
    }
    if (!hasSpecialChar.test(password)) {
      errorMessage += 'New Password must contain at least one special character. ';
    }
    if (!noSpaces.test(password)) {
      errorMessage += 'New Password must not contain spaces. ';
    }

    if (errorMessage) {
      this.toastr.error(errorMessage.trim(), 'Password Error');
      return false;
    } else {
      return true;
      // Password is valid, proceed with the next steps
    }
  }

onSubmit(): void {
  if(!this.old_password){
   this.toastr.error("Old password is required", 'Error');
  return;
  }
  if(!this.new_password){
   this.toastr.error("new password is required", 'Error');
  return;
  }
  if(!this.validatePassword(this.new_password)){
    return;
  }  
  if(!this.confirm_password){
   this.toastr.error("confirm password is required", 'Error');
  return;
  }
  if (this.old_password == this.new_password) {
    this.toastr.error('Current password and new password cannot be same.', 'Error');
    return;
  }
  if (this.new_password !== this.confirm_password) {
    this.toastr.error('Passwords do not match.', 'Error');
    return;
  }
  let data = {
    old_password: this.old_password,
    new_password: this.new_password,
    confirm_password: this.confirm_password,
  }
  console.log('data',data)
  this.usersService.changePassword(data).subscribe(
    (response:any) => {
      // this.spinner.hide();
      if(response.code == 200){
        
this.signOut()
      }
      this.dialogRef.close();

    },
    (error) => {
      // this.spinner.hide();
      console.error('Error', error);
      this.toastr.error(error?.error?.errorMessage, 'Error');

    }
  );
 }
}
